import { cn } from '@/lib/utils';
import { ArrowRight, GripVertical } from 'lucide-react';
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Button } from 'src/components/shad-base/button';
import { TOPBAR_HEIGHT } from 'src/config';
import useAppStore from 'src/hooks/store/useAppStore';

import useDevice from 'src/hooks/useDevice';
import useResize from 'src/hooks/useResize';
import BeforeYouContinueDialog from 'src/layouts/CollapsiblePanelLayout/BeforeYouContinueDialog';
import Subnav from 'src/layouts/Subnav';
import { PANEL } from 'tailwind.config';

export default function CollapsiblePanelLayout({
  children,
  getSubnav,
  getPanel,
  panelRef,
  childRef,
  onTogglePanel,
  panelFullscreenState,
  includePadding = false,
  preventHorizontalScroll = false,
  forceClose = false,
  hideBottomNav = false,
  bottomNavContent = null,
  bottomNavButtonText = '',
  bottomNavButtonDisabled = false,
  bottomNavOnClick = null,
  beforeYouContinueMessage = null,
  fillVerticalSpace = false
}: {
  children: React.ReactNode;
  getSubnav?: () => React.ReactNode;
  getPanel?: () => React.ReactNode;
  forceClose?: boolean;
  panelRef?: React.RefObject<HTMLDivElement>;
  childRef?: React.RefObject<HTMLDivElement>;
  panelFullscreenState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  includePadding?: boolean;
  preventHorizontalScroll?: boolean;
  onTogglePanel?: (isVisible) => void;
  hideBottomNav?: boolean;
  bottomNavContent?: React.ReactNode;
  bottomNavButtonText?: string;
  bottomNavButtonDisabled?: boolean;
  bottomNavOnClick?: () => void;
  beforeYouContinueMessage?: React.ReactNode;
  fillVerticalSpace?: boolean;
}) {
  const { panelMinimized, setPanelMinimized, sidebarMinimized } =
    useAppStore((store) => ({
      panelMinimized: store.panelMinimized,
      setPanelMinimized: store.setPanelMinimized,
      sidebarMinimized: store.sidebarMinimized
    }));

  const [
    beforeYouContinueDialogOpen,
    setBeforeYouContinueDialogOpen
  ] = useState(false);

  const dragbarRef = useRef(null);
  const [isOver, setIsOver] = useState(false);
  const { isMobile, isTablet } = useDevice();
  const isSmallScreen = isMobile || isTablet;
  const [isPanelFullscreen, setIsPanelFullscreen] =
    panelFullscreenState || [false, () => null];

  const setPanelMinimizedAndToggle = (minimized) => {
    setPanelMinimized(minimized);
    if (minimized) {
      setIsPanelFullscreen(false);
    }
    if (onTogglePanel) onTogglePanel(minimized);
  };

  // Add a keyboard shortcut to change width of panel
  // Command + B on Mac,
  // Control + B on Windows
  const handleKeyDown = (event) => {
    if (
      !forceClose &&
      event.key === 'b' &&
      (event.metaKey || event.ctrlKey)
    ) {
      setPanelMinimized(!panelMinimized);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [panelMinimized, isPanelFullscreen, forceClose]);

  const { enableResize, isResizing } = useResize({
    minSize: PANEL.MIN,
    maxSize: PANEL.MAX,
    rightAnchor: true,
    ref: panelRef,
    callback: () => {
      if (childRef?.current) {
        if (!panelMinimized) {
          childRef.current.style.width = `calc(100% - ${panelRef.current.offsetWidth}px + 4px)`;
        } else {
          childRef.current.style.width = '100%';
        }
      }
    }
  });

  useLayoutEffect(() => {
    if (childRef?.current) {
      if (panelMinimized || forceClose) {
        childRef.current.style.width = '100%';
      } else if (panelRef?.current) {
        childRef.current.style.width = `calc(100% - ${panelRef.current.offsetWidth}px + 4px)`;
      }
    }
  }, [panelMinimized, forceClose]);

  useEffect(() => {
    if (isTablet || isMobile) {
      setPanelMinimizedAndToggle(true);
    }
  }, [isTablet, isMobile]);

  const getPageHeightClass = () => {
    if (getSubnav && !hideBottomNav) {
      return 'h-page-height-subnav-bottomnav';
    } else if (getSubnav && hideBottomNav) {
      return 'h-page-height-subnav';
    } else if (!getSubnav && hideBottomNav) {
      return 'h-screen';
    }
  };
  const pageHeightClass = getPageHeightClass();

  return (
    <div className="flex h-full min-h-screen flex-col flex-nowrap">
      {getSubnav && (
        <Subnav getSubnav={getSubnav} forceClosePanel={forceClose} />
      )}
      <div className={'overflow-y-auto ' + pageHeightClass}>
        <div className="relative flex h-full w-full flex-nowrap ">
          {/* Child wrapper */}
          <div
            ref={childRef}
            className={'w-full ' + (hideBottomNav && 'h-full')}
          >
            <div
              className={
                'z-page flex flex-nowrap ' +
                ((fillVerticalSpace || hideBottomNav) && 'h-full ') +
                (includePadding ? 'flex-row ' : 'flex-col ') +
                (!preventHorizontalScroll && 'overflow-x-auto')
              }
              // style={{
              //   width: `calc(100% - ${
              //     (includePadding ? (panelMinimized ? 0 : 1) : 0) * 8
              //   }px)`
              // }}
            >
              {/* Children  */}
              {includePadding && (
                <>
                  <div
                    className={
                      'ml-lg flex max-w-full flex-col flex-nowrap items-start pt-1'
                    }
                    style={{
                      width: `calc(100% - ${includePadding ? 64 : 0}px)`,
                      minWidth: 'fit-content'
                    }}
                  >
                    <div className="w-full py-md" />
                    {children}
                    <div className="w-full py-lg" />
                  </div>
                  <div className="h-full px-md" />
                </>
              )}
              {!includePadding && children}
            </div>
          </div>
          {/* Panel Wrapper */}
          {!forceClose &&
            !panelMinimized &&
            !isSmallScreen &&
            !isMobile &&
            !isTablet &&
            getPanel && (
              <div
                className={
                  'z-page bg-background ' +
                  (getSubnav ? 'h-page-height-subnav' : 'h-screen')
                }
                style={{
                  position: 'fixed',
                  top: TOPBAR_HEIGHT,
                  bottom: 0,
                  right: 0
                }}
              >
                <div className="flex h-full min-h-full bg-background">
                  <div
                    className="relative bottom-0 left-0 top-[0.5px] w-[5px] border-l bg-background"
                    onMouseEnter={() => {
                      setIsOver(true);
                    }}
                    onMouseLeave={() => {
                      setIsOver(false);
                    }}
                    onMouseMove={(e) => {
                      if (dragbarRef.current) {
                        if (e.clientY < TOPBAR_HEIGHT + 25) {
                          return;
                        }
                        dragbarRef.current.style.top = `${
                          e.clientY - TOPBAR_HEIGHT - 25
                        }px`;
                      }
                    }}
                  >
                    {(isOver || isResizing) && (
                      <div
                        className="absolute"
                        ref={dragbarRef}
                        style={{
                          cursor: isResizing ? 'grabbing' : 'grab',
                          zIndex: 12000
                        }}
                        onMouseDown={enableResize}
                      >
                        <div
                          className="flex flex-col rounded-md bg-background"
                          style={{
                            transform: 'translateX(-14px)'
                          }}
                        >
                          <div className="flex items-center justify-center rounded-sm bg-white py-sm">
                            <GripVertical className="text-primary" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Panel */}
                  <div
                    className="h-page-height relative min-h-full w-panel overflow-y-auto px-md py-md pr-md"
                    ref={panelRef}
                  >
                    {getPanel()}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {!hideBottomNav && (
        <div
          className={cn(
            'h-bottom-nav fixed bottom-0 z-subnav flex items-center border-t bg-background ',
            sidebarMinimized
              ? 'w-left-min-sidebar-page'
              : 'w-left-sidebar-page'
          )}
        >
          {bottomNavContent}
          <div
            className={cn(
              'mx-lg',
              !bottomNavContent && 'flex w-full justify-end'
            )}
          >
            <Button
              variant={
                beforeYouContinueMessage ? 'outline' : 'default'
              }
              disabled={bottomNavButtonDisabled}
              onClick={
                beforeYouContinueMessage
                  ? () => setBeforeYouContinueDialogOpen(true)
                  : bottomNavOnClick
              }
            >
              <div className="mr-sm">{bottomNavButtonText}</div>
              <div>
                <ArrowRight className="h-icon w-icon" />
              </div>
            </Button>
          </div>
        </div>
      )}
      {beforeYouContinueMessage && (
        <BeforeYouContinueDialog
          open={beforeYouContinueDialogOpen}
          onOpenChange={setBeforeYouContinueDialogOpen}
          message={beforeYouContinueMessage}
          onContinue={bottomNavOnClick}
        />
      )}
    </div>
  );
}
